<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center">
        <slot name="icon"/>
      </h4>
      <h1 class="text-center">{{ value }}</h1>
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CounterCardComponent',
  props: {
    title: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style scoped>
.card-title .material-symbols-outlined {
  font-size: 3rem;;
}
</style>
